.version {
  border: solid 2px rgb(68, 68, 68);
  background-color: rgb(229, 232, 232);
  opacity: 0.3;
  position: absolute;
  left: -20px;
  bottom: -10px;
  padding: 2px;
  font-weight: 100;
  border-radius: 6px;
  transition: all 0.5s ease-in;
  transform: scale(0.7);
}

.version:hover {
  opacity: 1;
  transform: scale(1.1);
  left: 15px;
  bottom: 10px;
}
