/* --- Font Files --- */
@font-face {
  font-family: 'Suisse Medium';
  src: url('./fonts/SuisseIntl-Medium.otf');
  /*font-weight: Medium;*/
  /*font-style: Medium;*/
}
@font-face {
  font-family: 'Suisse Regular';
  src: url('./fonts/SuisseIntl-Regular.otf');
  font-weight: Normal;
  font-style: Normal;
}
/*
primary-dark: rgba(30, 30, 30);
secondary-dark: rgba(255,255,255, 0.05);
tertiary-dark: rgba(255, 255, 255, 0.15);
tertiary-light: rgba(255, 255, 255, 0.40);
secondary-light: rgba(255, 255, 255, 0.6);
primary-light: rgba(255, 255, 255, 1);
*/

:root {
  --background-primary: rgba(255, 255, 255, 1);
  --background-secondary: #f8f8f8;
  --background-tertiary: rgba(30, 30, 30, 0.03);

  --text-primary: rgba(30, 30, 30);
  --text-secondary: rgba(255, 255, 255, 0.6) !important;
  --text-tertiary: rgba(30, 30, 30, 0.03);

  --form-text: rgb(70, 70, 70);
  --form-text-bg: #e2e2e2;
  --form-text-active: #fff;
  --form-text-bg-active: rgba(255, 255, 255, 0.5);
  --form-text-placeholder: rgba(0, 0, 0, 0.2);

  --close-button-color: red !important;
  --chart-btn-bg: rgb(124, 186, 120) !important;
  --chart-btn-text: #fff !important;
  --chart-btn-text-hover: rgb(239, 239, 239) !important;
  --chart-btn-bg-hover: rgb(145, 213, 140) !important;

  --btn-bg: rgba(98, 197, 233, 1);
  --btn-text: #fff;
  --btn-text-hover: rgba(140, 220, 249, 1);
  --btn-bg-hover: rgba(98, 197, 233, 0.25);

  --chart-btn-secondary-bg: #40503f !important;

  --btn-cancel-text: rgba(253, 1, 1, 0.906);
  --btn-cancel-bg: rgba(192, 44, 44, 0.348);
  --btn-cancel-text-hover: rgb(249, 140, 140);
  --btn-cancel-bg-hover: rgba(233, 98, 98, 0.25);

  --error: rgba(255, 0, 0, 0.4);
  --success: #7cba78;

  /*--link-primary: #rgba(255, 255, 255, 0.6);*/
  --link-secondary: rgb(78, 162, 201);

  --border-primary: #f8f8f8;
  --border-secondary: #727171;
  --leaflet-tile-filter: none;
}

/* opposite of :root */
[data-theme='dark'] {
  --background-primary: rgb(30, 30, 30);
  --background-secondary: rgba(255, 255, 255, 0.05);
  --background-tertiary: rgba(255, 255, 255, 0.05);
  --background-solid: #292929;

  --text-primary: rgba(255, 255, 255, 1);
  --text-secondary: rgba(109, 109, 109, 0.05) !important;
  --text-tertiary: rgba(255, 255, 255, 0.4);

  --form-text: rgb(175, 175, 175);
  --form-text-bg: rgb(49, 49, 49);
  --form-text-active: rgb(139, 139, 139);
  --form-text-bg-active: rgba(255, 255, 255, 0.5);
  --form-text-placeholder: rgba(255, 255, 255, 0.2);

  --close-button-color: green !important;
  --chart-btn-text: rgb(109, 166, 105) !important;
  --chart-btn-bg: #40503f !important;
  --chart-btn-text-hover: rgba(124, 186, 120, 1) !important;
  --chart-btn-bg-hover: #566855 !important;

  --btn-text: rgba(98, 197, 233, 1);
  --btn-bg: rgba(98, 197, 233, 0.15);
  --btn-text-hover: rgba(140, 220, 249, 1);
  --btn-bg-hover: rgba(98, 197, 233, 0.25);

  --btn-text-disabled: rgba(223, 222, 222, 0.6);
  --btn-bg-disabled: rgba(107, 107, 107, 0.6);

  --btn-cancel-text: rgba(253, 1, 1, 0.906);
  --btn-cancel-bg: rgba(192, 44, 44, 0.348);
  --btn-cancel-text-hover: rgb(249, 140, 140);
  --btn-cancel-bg-hover: rgba(233, 98, 98, 0.25);

  --error: rgba(255, 0, 0, 0.4);
  --success: #7cba78;

  /*--link-primary: #rgba(255, 255, 255, 0.6);*/
  --link-secondary: rgb(78, 162, 201);

  --border-primary: #2a2a2a;
  --border-secondary: #727171;
  --leaflet-tile-filter: brightness(0.6) invert(1) contrast(3)
    hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

html,
body,
#root {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
  position: relative;
  font-size: 16px;
  font-family: 'Suisse Regular', 'Suisse Medium', sans-serif;
}

#root {
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  position: relative;
  overflow: auto;
  font-size: 13px;
  font-weight: 400;
}

.relative {
  position: relative;
}
.w-90 {
  width: 90%;
  color: var(--border-secondary);
}
.app {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--background-primary);
  color: var(--text-primary);
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Suisse Medium', sans-serif;
  color: var(--text-primary);
}

a,
a:hover,
a:active {
  text-decoration: none;
  /*color: var(--link-primary) !important;*/
}

input::placeholder {
  font-weight: bold;
  opacity: 0.3;
}
.inventory-planner {
  position: absolute;
  top: 25%;
  bottom: 0;
}

.react-datepicker__header {
  text-align: center;
  background-color: var(--form-text-bg);
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__today-button {
  background: var(--btn-bg);
  /*color: var(--btn-text-bg);*/
  width: 50%;
  border-top: 0;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker {
  font-size: 1.2em;
  background-color: var(--form-text-bg);
  color: var(--form-text);
  width: 250px;
  border: 1px solid var(--border-secondary);
  display: inline-block;
  position: relative;
  border-radius: 0 0 8px 8px;
}

.react-datepicker button {
  width: 50%;
}

.react-datepicker__month-text {
  padding: 1px 5px;
}

main {
  padding: 0 5px;
  margin-bottom: 50px;
}

.form-control {
  background-color: var(--form-text-bg);
  color: var(--form-text);
  text-decoration: none;
  font-weight: normal !important;
  border: none;
}

.form-control:active,
.form-control:focus {
  background-color: var(--form-text-bg-active) !important;
}
.form-control::placeholder {
  color: var(--form-text-placeholder);
}

.form-control-label {
  color: var(--form-text);
  padding: 2px;
  text-decoration: none;
  font-weight: normal !important;
  border: none;
}

.loginLanding {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url('./images/pump-jack-canola.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
}

/* Color Scheme */
.s-bg {
  background-color: var(--background-secondary) !important;
}

.r-border,
.r-border-6,
.r-border-12 {
  border: none;
  overflow: hidden;
  padding: 0;
}

.r-border {
  border-radius: 20px;
}

.r-border-6 {
  border-radius: 6px;
}
.r-border-12 {
  border-radius: 12px;
}

.s-btn {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: var(--chart-btn-text) !important;
  background-color: var(--chart-btn-bg) !important;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--border-secondary);
}

.list-items {
  border-radius: 0 !important;
}

.list-items:first-child {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.list-items:last-child {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.p-text {
  color: var(--text-primary);
  font-size: 1rem;
}

.s-text {
  color: var(--text-secondary);
}

.p-link-btn {
  /*color: var(--link-primary);*/
  text-decoration: none;
}

/*.p-link-btn:hover {*/
/*color: var(--link-primary-hover);*/
/*}*/

.s-link-btn {
  color: var(--link-secondary);
}

/*.list__error {*/
/*  color: var(--text-danger);*/
/*  text-transform: "uppercase";*/
/*}*/

/* Ens Color Scheme */

/* Button styles */
.p-button,
.p-button:active,
  /*.p-button:focus {*/
  /*  color: var(----button-text);*/
  /*  background-color: var(--button-secondary);*/
  /*  border: 1px solid var(--button-border);*/
  /*}*/

.p-button:hover {
  outline: 2px solid var(--border-primary);
  /*background-color: calc(var(--button-secondary) * 25);*/
  /*color: var(----button-text);*/
  cursor: pointer;
}

/*.c-button,*/
/*.c-button:active,*/
/*.c-button:focus {*/
/*  background-color: var(--button-cancel);*/
/*}*/

/* .c-button:hover {
  outline: 2px solid var(--button-cancel);
  background-color: calc(var(--button-cancel)-* 25);
  color: var(--text-primary);
  cursor: pointer;
} */

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

/* End Button styles */

/* Text Styles */
.text-16 {
  font-size: 16px;
}

.bold {
  font-weight: bold;
  line-height: 1.2rem;
}
/* End Text Styles */

/*  Login Controls */
.login {
  width: 500px;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px var(--border-primary);
  background-color: var(--background-primary);
}

.login .control {
  width: 100%;
  font-size: 1.5em;
  margin: 0.5em 0;
}

.login .control label {
  display: flex;
  width: 100%;
  align-self: flex-end;
  justify-content: space-between;
}

.login .control span {
  width: 25%;
}

.login .control input[type='text'] {
  width: 75%;
  border-radius: 10px;
  border: solid 1px var(--border-primary);
  outline: none;
  color: var(--text-primary);
  background-color: var(--background-secondary);
  height: 2em;
  padding: 0 0.5em;
}

.login .control input[name='security-code'] {
  width: 60%;
}

/* Login Controls End */

/* Charts */
.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 10000;
}
.modal-container,
.modal-body,
.modal-header,
.modal-footer {
  color: var(--text-secondary) !important;
  background-color: var(--background-primary) !important;
}

.modal-body {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
.modal-backdrop {
  background-color: var(--background-primary) !important;
}

.modal-header .btn-close {
  color: var(--close-button-color) !important;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  margin: 0;
  border: none;
  background-color: transparent;
  color: var(--text-secondary);
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
}

.modal-header {
  position: relative;
  margin: 10px;
  padding: 10px;
  border-bottom: transparent !important;
}

.chart__container {
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
}

.chart__container > svg {
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.main-svg {
  width: 100%;
  height: 100%;
}

/* tables */
/* cost model table */
.table {
  color: var(--text-primary) !important;
  /*background-color: none !important;*/
}

.table > thead > tr,
.table > thead > tr > td,
.table > tbody > tr,
.table > tbody > tr > td {
  background-color: var(--background-tertiary);
  color: var(--text-primary) !important;
  border: none;
}

.table > thead > tr,
.table > thead > tr > td {
  opacity: 0.6;
  font-size: 16px;
  /*background-color: none !important;*/
}

.table > tbody > tr {
  border-bottom: 5px solid var(--border-primary);
}

.table > tbody > tr > td {
  padding: 5px;
  background-color: var(--background-tertiary);
  color: var(--text-primary) !important;
  border-left: 5px solid var(--border-primary);
  border-radius: 10px;
}
/*.table > thead > tr,*/
/*.table > thead > tr > td {*/
/*  background-color: none !important;*/
/*}*/

.row > * {
  background-color: transparent;
}

.rdt_Table > div {
  color: var(--text-primary) !important;
  background-color: transparent !important;
}

.rdt_Table,
.rdt_TableRow,
.rdt_TableCell,
.rdt_TableHeader,
.rdt_TableHead,
.rdt_TableHeadRow,
.rdt_TableHeadCell div,
.rdt_TableHeadCell .rdt_TableCol_Sortable,
.rdt_TableCol,
.rdt_ExpanderRow,
.rdt_Pagination {
  color: var(--text-primary) !important;
  background-color: var(--background-secondary) !important;
  border-top: 2px solid var(--border-primary) !important;
  border-left: 2px solid var(--border-primary) !important;
  /*border-bottom-color: none !important;*/
  outline: none !important;
}

.rdt_TableHeadRow > div {
  color: var(--text-primary) !important;
  background-color: var(--background-solid) !important;
}
.rdt_TableRow:hover {
  /*border-bottom-color: none !important;*/
  outline: none !important;
}

.table_container {
  min-height: 500px;
}
.rdt_Pagination button svg,
.rdt_TableRow button svg {
  fill: var(--text-primary) !important;
}
.rdt_Pagination button:disabled svg,
.rdt_TableRow:disabled button svg {
  fill: var(--text-tertiary) !important;
}

.rdt_TableCell a,
.rdt_TableCell a:hover,
.rdt_TableCell a:active {
  font-size: 1em;
  text-decoration: none;
  color: var(--link-secondary) !important;
  background-color: transparent !important;
}

/* .rdt_Table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rdt_Table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* end tables */

.p-box-shadow {
  -webkit-box-shadow: -5px -3px 7px -1px rgba(0, 0, 0, 0.8);
  box-shadow: -5px -3px 7px -1px rgba(0, 0, 0, 0.8);
}

table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}

table.table-fit thead th,
table.table-fit tfoot th {
  width: auto !important;
}

table.table-fit tbody td,
table.table-fit tfoot td {
  width: auto !important;
}

.tableTooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
}

.tableTooltip .tableTooltiptext {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tableTooltip:hover .tableTooltiptext {
  visibility: visible;
}
/* Table Headers will be multiline and not overflow */
.rdt_TableCol_Sortable * {
  white-space: normal !important;
}
.checkbox__input {
  background-color: var(--background-secondary);
}
/* tables end */

.app_border {
  border: 2px solid var(--border-primary);
  border-radius: 5px;
}

.btn_min--width {
  min-width: 200px;
}
.brand-link-home {
  width: 40%;
}

.navbar__brand--img {
  height: 60px;
  margin-left: 20px;
  color: var(--text-primary);
}

.navbar__theme--img path {
  fill: var(--text-primary);
  stroke-width: 2px;
}
.data-description {
  max-width: 200px;
  white-space: -o-pre-wrap;
  margin: 2px;
}

/* z-index is set to high number
because map on page has many layers,
so the button on map has to have a
bigger number than map and
topNav must be higher than buttons*/
.navbar-custom {
  height: 100px;
  z-index: 10000;
}
.site-map {
  position: relative;
  width: 100%;
  height: 100%;
}
.map__container {
  position: absolute;
  overflow: hidden;
  contain: content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.btn__container-map {
  position: absolute;
  top: 10px;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10000;
}
.map__container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.btn__container {
  display: flex;
  position: absolute;
  top: 10px;
  right: 5px;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;
  z-index: 2;
}
.btn__container button {
  margin: 1px;
  align-self: flex-end;
}
.map__toggle-btn,
.map__toggle-btn:active,
.map__toggle-btn:focus {
  color: var(--chart-btn-text) !important;
  background-color: var(--chart-btn-bg) !important;
  border: none;
  outline: none !important;
  box-shadow: none;
  font-size: 0.875rem;
  font-family: 'Suisse Medium', sans-serif;
  z-index: 10000;
}
.map__range__container {
  position: absolute;
  width: 80px;
  top: 40px;
  right: 5px;
  z-index: 2;
}
.chart__toggle-btn,
.chart__toggle-btn:active,
.chart__toggle-btn:focus {
  color: var(--chart-btn-text) !important;
  background-color: var(--chart-btn-bg) !important;
  border: none;
  outline: none !important;
  box-shadow: none;
  font-size: 0.875rem;
  font-family: 'Suisse Medium', sans-serif;
  z-index: 1080;
}

.chart__toggle-btn:hover {
  color: var(--chart-btn-text-hover) !important;
  background-color: var(--chart-btn-bg-hover) !important;
}

.btn__container div,
.btn__container a {
  color: var(--chart-btn-text-hover) !important;
  background-color: var(--chart-btn-bg) !important;
}

.save__btn,
.cancel__btn {
  white-space: nowrap;
  line-height: 1.5rem;
  border: none;
}

.save__btn {
  color: var(--btn-text) !important;
  background-color: var(--btn-bg) !important;
}
.save__btn:disabled {
  color: var(--btn-text-disabled) !important;
  background-color: var(--btn-bg-disabled) !important;
}

.save__btn:hover {
  color: var(--btn-text-hover) !important;
  background-color: var(--btn-bg-hover) !important;
}

.cancel__btn {
  color: var(--btn-cancel-text) !important;
  background-color: var(--btn-cancel-bg) !important;
}

.cancel__btn:hover {
  color: var(--btn-cancel-text-hover) !important;
  background-color: var(--btn-cancel-bg-hover) !important;
}

.link__btn {
  margin-right: 10px;
  white-space: nowrap;
  line-height: 1.5rem;
  margin-top: 5px;
  color: var(--btn-text) !important;
  text-decoration: none;
  border: none;
}

.link__btn:disabled {
  display: none;
}

.shared__user {
  margin-left: -30px;
  display: flex;
  list-style: none;
  justify-content: space-between;
}

.shared__user {
  height: 2.5rem;
  font-size: 1rem;
  border: none;
}

.shared__user span,
.shared__user-btn {
  height: 2rem;
  font-size: 1rem;
  border: none;
}

.shared__user span {
  line-height: 2rem;
  padding: 0 10px;
}
.shared__user-btn {
  line-height: 1.2rem;
}
.shared__user:nth-child(odd) {
  background-color: var(--background-secondary);
}
.home__btn {
  margin-right: 10px;
}

.save__btn-icon {
  margin: 1px 5px 1px 10px;
  font-size: 1.2rem;
}

.landing-logo__img {
  height: 120px;
  margin-left: 20px;
}

.not-found {
  display: flex;
  justify-content: center;
}

.not-found__container {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.not-found__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav_link,
.navbar-light .navbar-nav .nav-link {
  line-height: 3rem;
  font-weight: 400;
  font-size: 1.2rem;
  text-decoration: none;
  /*color: var(--link-primary) !important;*/
}

.link_container,
div:has(.link_container) {
  background-color: var(--background-primary);
  color: var(--text-primary);
}

.nav_link > div:has(.link_container) {
  margin-top: -10px;
  /*border: 1px solid var(--border-tertiary);*/
}

.dropdown-item.active,
.dropdown-item:active {
  /*background-color: var(--background-);*/
  color: var(--text-primary);
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--text-primary);
  background-color: var(--background-tertiary);
}

.nav_link_button {
  margin-right: 10px;
  min-height: 3rem;
  max-width: 200px;
  line-height: 3rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--text-primary);
}

.authentication-user__link {
  /*color: var(--link-primary);*/
  text-align: right;
  white-space: nowrap;
}

.icon-bug {
  margin: -2px 8px 0 0;
  /*font-size: 1remem;*/
  fill: var(--text-primary) !important;
  -webkit-transition: -webkit-transform 0.8s ease-in-out, color 3s ease-in-out;
  -ms-transition: -ms-transform 0.8s ease-in-out, color 3s ease-in-out;
  transition: transform 0.8s ease-in-out, color 3s ease-in-out;
}

.icon-bug:hover {
  transform: rotate(90deg);
  fill: var(--text-primary) !important;
}

.map-icon {
  fill: var(--text-primary) !important;
  -webkit-transition: -webkit-transform 0.8s ease-in-out, color 3s ease-in-out;
  -ms-transition: -ms-transform 0.8s ease-in-out, color 3s ease-in-out;
  transition: transform 0.8s ease-in-out, color 3s ease-in-out;
}

.abatement-projectType {
  width: 100%;
  height: 100%;
  /* text-align: center; */
  border-radius: 10px;
  border: 1px solid #999595;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

.link-btn-right-text {
  display: flex;
  align-items: center;
  justify-content: center;
  /*align-self: middle;*/
  padding: 10px;
  width: 100%;
  height: 400px;
  min-width: 25%;
  min-height: 100px;
  margin-top: 10px;
  font-size: 2em;
  font-weight: bolder;
  border-radius: 20px;
}

.disabled {
  pointer-events: none;
  /*border: solid 1px var(--disabled-border);*/
  /*color: var(--disabled-text);*/
  /*background-color: var(--disabled-bg);*/
}

.disabled:hover {
  cursor: not-allowed;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.authentication-user {
  margin: 10px;
}

.version {
  border: solid 2px var(--border-primary);
  color: var(--text-primary);
  background-color: var(--background-primary);
  opacity: 0.3;
  position: fixed;
  left: -20px;
  bottom: -10px;
  padding: 2px;
  font-weight: 100;
  border-radius: 6px;
  transition: all 0.5s ease-in;
  transform: scale(0.7);
}

.version:hover {
  opacity: 1;
  transform: scale(1.1);
  left: 15px;
  bottom: 10px;
}

/* Popup info icon, display text on hover */
.popup {
  position: relative;
  cursor: default;
  font-weight: 700;
  font-size: 10px;
  margin: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border: solid 1px #333;
  border-radius: 50%;
}

.popup:hover .popup-display {
  display: inline-block;
}

.popup__display {
  display: none;
  position: absolute;
  top: 0;
  left: 90%;
  width: 300px;
  min-height: 25px;
  padding: 3px 10px 3px 10px;
  border: solid 1px #333;
  border-radius: 10px;
  z-index: 1000;
  overflow: hidden;
  /*text-align: "left";*/
  font-size: 12px;
  font-weight: 400;
}

.popup__display-message {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial, serif;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border-color: transparent;
  border-top: 6px solid #fff;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.clHide {
  display: none;
}

/* .clTab-header:hover, .clHide ,  */
.clTab-header:hover + .clHide {
  position: absolute;
  top: 30px;
  left: 10px;
  padding: 15px;
  border: solid #333 2px;
  border-radius: 10px;
  display: block;
  font-weight: bold;
  opacity: 0.99;
}

.pathwayCard,
.projectCard,
.generationCard {
  /* border: 2px solid rgb(94, 87, 87); */
  margin: 5px;
  padding: 10px;
  contain: content;
}

.scoreCard {
  margin: 2px;
  max-width: 200px;
  padding: 10px;
  background-color: var(--background-tertiary);
}

.scoreCard .scoreCardLabel {
  font-size: 1em;
  /* text-decoration: underline; */
}

.scoreCard .scoreCardValue {
  font-size: 1.4em;
  display: inline;
  width: fit-content;
}

.scoreCard .scoreCardUnit {
  font-size: 0.7em;
  margin-top: 0.6em;
  display: inline;
  width: fit-content;
  padding-left: 5px;
  /* text-align: right; */
}

.scoreCard .scoreCardSubtext {
  font-size: 0.7em;
  margin-top: 0.6em;
  display: block;
  padding-left: 5px;
  /* text-align: right; */
}
.scoreCard .scoreCardCurrency {
  font-size: 0.7em;
  margin-top: 0.6em;
  padding-right: 5px;
  /* text-align: right; */
}
.scoreCard .scoreCardSubtext {
  font-size: 0.7em;
  margin-top: 0.6em;
  display: block;
  padding-left: 5px;
  /* text-align: right; */
}

.warningText {
  color: var(--error);
  font-style: italic;
}

.react-datepicker__month-container {
  /* background-color: beige; */
}

/* plotly.js */

.js-plotly-plot {
  background-color: var(--background-secondary);
  width: 100%;
  height: 100%;
}

.js-plotly-plot .plotly .modebar-container {
  position: relative;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  top: 92% !important;
}
.js-plotly-plot .plotly .modebar {
  display: flex;
  width: 25%;
  left: 0;
}

.js-plotly-plot .plotly .modebar svg {
  align-items: flex-end;
  right: 0 !important;
  bottom: 0 !important;
  line-height: 2em;
}
.js-plotly-plot .plotly,
.js-plotly-plot .plotly div {
  min-height: 450px !important;
}

/* end plotly.js */

.bartext.bartext-inside,
.bartext.bartext-outside {
  /*stroke-width: "0" !important;*/
  stroke: none !important;
  fill: none !important;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 90%;
  }
}
/* SitesGeo */
#map {
  height: 100% !important;
  overflow: hidden;
}
.map-box {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.map-box-fullscreen {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.range__container-map {
  position: absolute;
  top: 50px;
  left: -27px;
  transform: rotate(-90deg);
  width: 100px;
  padding: 5px;
  border-radius: 5px;
  height: 25px;
  background-color: var(--background-secondary);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.leaflet-control-zoom-out,
a.leaflet-control-zoom-in {
  color: var(--text-primary) !important;
  background-color: var(--background-primary);
  border-radius: 50% !important;
  line-height: 10px;
  overflow: hidden;
  margin: 5px 0;
}

.leaflet-left .leaflet-control {
  margin-left: 20px;
  border: none;
}

.leaflet-tile {
  filter: var(--leaflet-tile-filter, none);
}

.leaflet-container {
  width: 100%;
  background: #303030;
}

input[type='range'] {
  height: 28px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0 0 #000000;
  background: #3071a9;
  border-radius: 2px;
  border: 0 solid #000000;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 1px 1px 3px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 8px;
  border-radius: 19px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #3071a9;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0 0 #000000;
  background: #3071a9;
  border-radius: 2px;
  border: 0 solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 3px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 8px;
  border-radius: 19px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  transition: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #3071a9;
  border: 0 solid #000000;
  border-radius: 4px;
  box-shadow: 0 0 0 #000000;
}
input[type='range']::-ms-fill-upper {
  background: #3071a9;
  border: 0 solid #000000;
  border-radius: 4px;
  box-shadow: 0 0 0 #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 3px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 8px;
  border-radius: 19px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type='range']:focus::-ms-fill-upper {
  background: #3071a9;
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {}  */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 2048px) {}  */
